
import { get, post } from "@/api/axios";
import { Component, Vue } from "vue-property-decorator";
import { IExaGroup, IExaTask } from "../../state/models";

@Component
export default class ExaTaskList extends Vue {
    tasks: Array<IExaTask> = [];
    fields: Array<{ icon: string, field: string }> = [
        { icon: "mdi-clock", field: "name" },
    ];
    headers: Array<any> = [
        {
            text: "Имя",
            align: "start",
            value: "name"
        },
        {
            value: 'actions',
            align: 'end'
        }
    ];
    search: string = "";

    caption(item: IExaGroup) {
        return item.name;
    }

    mounted() {
        get(`/api/@examination/exa/task`)
            .then((x) => {
                this.tasks = x.data;
                console.log(x.data);
            });
    }
    createEmptyTask() {
        post(`/api/@examination/exa/sku/global/task`, { taskName: "empty task name" })
            .then(x => {
                this.$router.push({ name: "examinationTaskEdit", params: { id: x.data._id } });
            })
    }


    goToEdit(item: IExaGroup) {
        this.$router.push({ name: "examinationTaskEdit", params: { id: item._id } });
    }
}
